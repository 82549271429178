@file:OptIn(ExperimentalJsExport::class)

package com.aldisued.foundation.mobilewebjsbridge.dtos

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlin.js.JsName

@JsExport
@Serializable
data class RefreshLoginDTOOut(
    @SerialName("authenticationData")
    @JsName("authenticationData")
    val mAuthenticationData: AuthenticationDataDTOOut?,
)