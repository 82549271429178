@file:OptIn(ExperimentalJsExport::class)

package com.aldisued.foundation.mobilewebjsbridge.dtos

import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
@Serializable
object EmptyDTO