package com.aldisued.foundation.mobilewebjsbridge

import kotlinx.browser.window

data class NativeBridgeWrapper(
    val mNativeBridge: dynamic,
    val mNativeBridgeType: EJsBridgeType,
) {
    enum class EJsBridgeType {
        ANDROID,
        IOS,
    }

    fun request(json: String) {
        when (mNativeBridgeType) {
            EJsBridgeType.ANDROID -> {
                mNativeBridge.request(json)
            }

            EJsBridgeType.IOS -> {
                mNativeBridge.postMessage(json)
            }
        }
    }

    companion object {
        fun initialize(): NativeBridgeWrapper? {

            try {
                return getAndroidBridge()
            } catch (e: dynamic) {
                console.log("Could not get Android bridge: $e")
            }

            try {
                return getIOSBridge()
            } catch (e: dynamic) {
                console.log("Could not get iOS bridge: $e")
            }

            return null
        }

        private fun getAndroidBridge(): NativeBridgeWrapper {
            val androidBridge = window.asDynamic().Android
            if (androidBridge != undefined) {
                return NativeBridgeWrapper(androidBridge, EJsBridgeType.ANDROID)
            } else {
                throw Exception("Android bridge is undefined")
            }
        }

        private fun getIOSBridge(): NativeBridgeWrapper {
            val iOSBridge = window.asDynamic().webkit.messageHandlers.iOS
            if (iOSBridge != undefined) {
                return NativeBridgeWrapper(iOSBridge, EJsBridgeType.IOS)
            } else {
                throw Exception("iOS bridge is undefined")
            }
        }
    }
}