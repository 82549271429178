package com.aldisued.foundation.mobilewebjsbridge

import com.aldisued.foundation.mobilewebjsbridge.dtos.GetImageDTOIn
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed interface EJsBridgeNativeApi {

    val mRequestId: String

    @Serializable
    @SerialName("PostOnJsBridgeReady")
    data class PostOnJsBridgeReady(
        override val mRequestId: String,
    ) : EJsBridgeNativeApi

    @Serializable
    @SerialName("GetImage")
    data class GetImage(
        val mDtoIn: GetImageDTOIn,
        override val mRequestId: String,
    ) : EJsBridgeNativeApi

    @Serializable
    @SerialName("GetCurrentAuthenticationData")
    data class GetCurrentAuthenticationData(
        override val mRequestId: String,
    ) : EJsBridgeNativeApi

    @Serializable
    @SerialName("RefreshLogin")
    data class RefreshLogin(
        override val mRequestId: String,
    ) : EJsBridgeNativeApi
}