@file:OptIn(ExperimentalJsExport::class)

package com.aldisued.foundation.mobilewebjsbridge

import com.aldisued.foundation.mobilewebjsbridge.dtos.EmptyDTO
import com.aldisued.foundation.mobilewebjsbridge.dtos.ErrorDTOOut
import com.aldisued.foundation.mobilewebjsbridge.dtos.ErrorDTOOut.Companion.ERROR_CODE_JS_BRIDGE_NOT_AVAILABLE
import com.aldisued.foundation.mobilewebjsbridge.dtos.GetCurrentAuthenticationDataDTOOut
import com.aldisued.foundation.mobilewebjsbridge.dtos.GetImageDTOIn
import com.aldisued.foundation.mobilewebjsbridge.dtos.GetImageDTOOut
import com.aldisued.foundation.mobilewebjsbridge.dtos.RefreshLoginDTOOut
import com.aldisued.foundation.mobilewebjsbridge.exceptions.JsBridgeException
import com.aldisued.foundation.mobilewebjsbridge.extensions.decodeViaStringify
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.js.Promise
import kotlin.uuid.ExperimentalUuidApi
import kotlin.uuid.Uuid

@JsExport
class JsBridge() {

    private val mNativeBridge: NativeBridgeWrapper? by lazy {
        NativeBridgeWrapper.initialize()
    }

    private val mJson = Json {
        ignoreUnknownKeys = true
    }

    private val mResponseCallbacks = mutableMapOf<String, PromiseCallbacks>()

    private var mLastSharedUserImage: GetImageDTOOut? = null
    private var mOnUserSharedImageCallback: ((GetImageDTOOut) -> Unit)? = null

    private fun callNativeApi(
        api: EJsBridgeNativeApi,
    ): Promise<dynamic> = Promise(
        executor = { resolve, reject ->
            val nativeBridge = mNativeBridge ?: run {
                reject(
                    JsBridgeException(
                        ERROR_CODE_JS_BRIDGE_NOT_AVAILABLE,
                        null,
                    )
                )
                return@Promise
            }

            val jsonString = mJson.encodeToString(
                api,
            )

            nativeBridge.request(jsonString)

            mResponseCallbacks[api.mRequestId] = PromiseCallbacks(
                resolve,
                reject,
            )
        }
    )

    @OptIn(ExperimentalUuidApi::class)
    private fun generateRequestId(): String = Uuid.random().toString()

    @JsName("isBridgeAvailable")
    fun isBridgeAvailable(): Boolean = mNativeBridge != null

    @JsName("onResponse")
    fun onResponse(requestId: String, dtoOut: dynamic) {
        val responseCallback =
            mResponseCallbacks.remove(requestId) ?: throw UnhandledResponseException()
        responseCallback.resolve(dtoOut)
    }

    @JsName("onResponseError")
    fun onResponseError(requestId: String, dtoOut: dynamic) {
        val responseCallback =
            mResponseCallbacks.remove(requestId) ?: throw UnhandledResponseException()
        val errorDTOOut = mJson.decodeViaStringify<ErrorDTOOut>(dtoOut)
        val throwable = JsBridgeException(
            mErrorCode = errorDTOOut.mCode,
            message = errorDTOOut.mMessage,
        )

        responseCallback.reject(throwable)
    }

    @JsName("onUserSharedImage")
    fun onUserSharedImage(dtoOut: dynamic) {
        val deserializedDtoOut = mJson.decodeViaStringify<GetImageDTOOut>(dtoOut)
        mLastSharedUserImage = deserializedDtoOut
        mOnUserSharedImageCallback?.invoke(deserializedDtoOut)
    }

    @JsName("onJsBridgeReady")
    fun onJsBridgeReady() = callNativeApi(
        EJsBridgeNativeApi.PostOnJsBridgeReady(
            mRequestId = generateRequestId(),
        )
    ).then<dynamic, EmptyDTO> {
        mJson.decodeViaStringify<EmptyDTO>(it)
    }

    @JsName("getImage")
    fun getImage() = callNativeApi(
        EJsBridgeNativeApi.GetImage(
            GetImageDTOIn(
                mUseDocumentScanner = false,
            ),
            mRequestId = generateRequestId(),
        )
    ).then<dynamic, GetImageDTOOut> {
        mJson.decodeViaStringify<GetImageDTOOut>(it)
    }

    @JsName("getImageWithDocumentScanner")
    fun getImageWithDocumentScanner() = callNativeApi(
        EJsBridgeNativeApi.GetImage(
            GetImageDTOIn(
                mUseDocumentScanner = true,
            ),
            mRequestId = generateRequestId(),
        )
    ).then<dynamic, GetImageDTOOut> {
        mJson.decodeViaStringify<GetImageDTOOut>(it)
    }

    @JsName("getCurrentAuthenticationData")
    fun getCurrentAuthenticationData() = callNativeApi(
        EJsBridgeNativeApi.GetCurrentAuthenticationData(
            mRequestId = generateRequestId(),
        )
    ).then<dynamic, GetCurrentAuthenticationDataDTOOut> {
        mJson.decodeViaStringify<GetCurrentAuthenticationDataDTOOut>(it)
    }

    @JsName("refreshLogin")
    fun refreshLogin() = callNativeApi(
        EJsBridgeNativeApi.RefreshLogin(
            mRequestId = generateRequestId(),
        )
    ).then<dynamic, RefreshLoginDTOOut> {
        mJson.decodeViaStringify<RefreshLoginDTOOut>(it)
    }

    @JsName("setOnUserSharedImageCallback")
    fun setOnUserSharedImageCallback(
        onUserShareImageUpdated: (GetImageDTOOut) -> Unit,
    ) {
        mLastSharedUserImage?.let {
            onUserShareImageUpdated(it)
        }
        mOnUserSharedImageCallback = onUserShareImageUpdated
    }
}


@JsExport
fun createJsBridge() = JsBridge()