@file:OptIn(ExperimentalJsExport::class)

package com.aldisued.foundation.mobilewebjsbridge.dtos

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlin.js.JsName

@JsExport
@Serializable
data class AuthenticationDataDTOOut(
    @SerialName("idToken")
    @JsName("idToken")
    val mIdToken: String,

    @SerialName("accessToken")
    @JsName("accessToken")
    val mAccessToken: String,

    @SerialName("refreshToken")
    @JsName("refreshToken")
    val mRefreshToken: String,
)