@file:OptIn(ExperimentalJsExport::class)

package com.aldisued.foundation.mobilewebjsbridge.dtos

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
@Serializable
data class ErrorDTOOut(
    @SerialName("code")
    val mCode: Int,
    @SerialName("message")
    val mMessage: String?,
) {
    companion object {
        const val ERROR_CODE_UNKNOWN = 0
        const val ERROR_CODE_USER_CANCELLED_TAKING_IMAGE = 1
        const val ERROR_CODE_JS_BRIDGE_NOT_AVAILABLE = 2
        const val ERROR_CODE_UNKNOWN_AUTHENTICATION_ERROR = 3
        const val ERROR_CODE_NO_INTERNET_DURING_AUTHENTICATION = 4
        const val ERROR_CODE_INSUFFICIENT_PERMISSIONS_FOR_AUTHENTICATION = 5
        const val ERROR_CODE_ILLEGAL_STATE = 6
    }
}